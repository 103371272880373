<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">类型:</div>
            <span>{{auditList[auditType].label}}</span>
        </div>
        <div class="manage_container">
            <div class="manage">类型:</div>
            <el-link @click="downloadFile" type="primary">下载教材</el-link>
        </div>
        <div class="manage_container">
            <div class="manage">上传时间:</div>
            <span>{{createTimeHandler(this.time)}}</span>
        </div>
    </div>

</template>

<script>
    import axios from '@/libs/axios'
    import {compressFile} from "@/utils/fileUtil";
    import {getData} from '@/utils/cookies'
    import {getDateFromTimeStamp} from "@/libs/tools";

    export default {
        name: "add",

        data() {
            return {
                auditList: [
                    {type: 1, label: '上传Excel'}, {type: 2, label: '添加教材'},
                    {type: 3, label: '删除教材'}, {type: 4, label: '更新教材'},
                    {type: 5, label: '添加单元'}, {type: 6, label: '删除单元'},
                    {type: 7, label: '更新单元'}, {type: 8, label: '添加章节'},
                    {type: 9, label: '删除章节'}, {type: 10, label: '更新章节'},
                    {type: 11, label: '添加字词'}, {type: 12, label: '删除字词'},
                    {type: 13, label: '更新字词'}
                ],
                auditType: 2,//审核类型
                auditName: '',//审核出处
                id: '',//操作id
                time: '',//创建时间
                auditTime: '',//审核时间
                url: ""
            }
        },
        mounted() {
            console.log(getData('auditInfo'));
            this.auditType = getData('auditInfo').type - 1
            this.auditName = getData('auditInfo').name
            this.time = getData('auditInfo').createTime
            this.id = getData('auditInfo').id
            this.auditTime = getData('auditInfo').auditTime
            this.getAuditDetail()
        },
        methods: {
            getUrl(url) {
                return axios.getUrl(url);
            },
            imageUpload(request) {
                this.loading = true;
                compressFile(request.file, (file) => {
                    axios.upload("/v1/web/file/image", file).then(response => {
                        this.localImageUrl = URL.createObjectURL(file);
                        this.imageUrl = response.data.data.url;
                        this.loading = false;
                        console.log(this.imageUrl);
                        console.log(this.localImageUrl);
                    }).catch((e) => {
                        console.log(e)
                    });
                })
            },
            //获取修改详情
            getAuditDetail() {
                this.$store.dispatch("user/detailList", {
                    'id': this.id
                }).then((data) => {
                    this.url = axios.getUrl(data.url);
                })
            },
            downloadFile() {
                this.saveShareContent(this.url,'待审核.xlsx')
            },
            //保存下载的excel
            saveShareContent(content, fileName) {
                let downLink = document.createElement('a')
                downLink.download = fileName
                downLink.href = content
                // 链接插入到页面
                document.body.appendChild(downLink)
                downLink.click()
                // 移除下载链接
                document.body.removeChild(downLink)
            },
            //时间戳转换工具
            createTimeHandler(timeStamp) {
                return getDateFromTimeStamp(timeStamp)
            },

        }
    }
</script>


<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

        .table_container {
            width: 80%;
            height: auto;
            border: 1px solid #E4E7ED;
            color: #606266;
        }

        .top_container {
            width: 100%;
            height: 40px;
            display: flex;

        }

        .left_container {
            flex: 1;
            border: 1px solid #E4E7ED;
            line-height: 40px;
        }

        .right_container {
            flex: 1;
            border: 1px solid #E4E7ED;
            line-height: 40px;
            text-align: center;
        }

        .bottom_container {
            width: 100%;
            min-height: 100px;
            display: flex;
        }

        .line_class {
            line-height: 20px;
        }

        .img_container {
            display: flex;
            align-items: center;
        }
        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }


    }

</style>
